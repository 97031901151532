<template>
  <v-app>
    <div class="d-flex">
      <v-text-field
        v-model="search"
        prepend-icon="mdi-magnify"
        label="Filter"
        dense
        clearable
      ></v-text-field>

      <Filters />

      <div><es-btn @click="addNew" color="teal"><fa icon="plus" /> Add New</es-btn></div>
      <div><es-btn @click="refresh" color="indigo"><fa icon="sync" /> Refresh</es-btn></div>
    </div>
    <v-card class='mb-6'>
      <v-data-table
        hide-default-footer
        fixed-header
        height="70vh"
        sort-by='updated'
        :must-sort='true'
        :sort-desc='true'
        :search='search'
        :headers='headers'
        :items='items'
        :header-props="{
          sortIcon: 'mdi-menu-down',
        }"
        :items-per-page='10000'
        :loading='busy'
        loading-text='Loading items ...'
        no-data-text='No items found.'
      >
        <div v-show="busy" slot="progress" class="dot-windmill"></div>
        <template v-slot:[`item.updated`]="{ item }">
          {{ item.formatted_updated }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="actions d-flex">
            <es-btn
              :title="`Copy item ID to clipboard`"
              class="es-btn es-btn-copy ml-auto"
              color="primary"
              @click="copyToClipboard(item)"
            ><fa icon="copy" /></es-btn>
            <es-btn
              :title="`Edit item`"
              class="es-btn es-btn-copy white--text"
              color="secondary"
              @click="navigateToItem(item)"
            ><v-icon dark dense>mdi-pencil</v-icon></es-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Filters from '@/components/item/Filters'
import * as clipboardy from 'clipboardy'

export default {
  data: () => ({
    search: ''
  }),
  components: {
    Filters,
  },
  computed: {
    ...mapState('list', [
      'headers',
      'items',
      'busy',
    ]),
  },
  methods: {
    // @note this.$router is not available in the vuex store, so navigation's handled here.
    navigateToItem({ item_id }) {
      this.$router.push(`/items/update/${item_id}`)
    },
    addNew() {
      // @todo display the item to-do list in a modal
      this.$router.push('/items/to-do')
    },
    async copyToClipboard({ item_id }) {
      await clipboardy.write(item_id);
      this.$store.dispatch('snackbar/snack', {
        mode: 'success',
        message: `✅ Copied to clipboard: <strong class="px-4">${item_id}</strong>`
      })
    },
    refresh() {
      this.$store.dispatch('list/fetchItems')
    },
  },
  watch: {
    search(value) {
      if (this.$route.query.search === value) return

      const query = { ... this.$route.query }
      if (!value) delete query.search
      else query.search = value
      this.$router.push({ name: "ItemsList", params: this.$route.params, query })
    },
    $route: {
      handler() {
        this.search = this.$route.query.search
      },
      immediate: true
    }
  },
  mounted() {
    this.refresh()
  },
}
</script>
<style lang='scss' scoped>
.dot-windmill {
  position: absolute;
  top: 84px;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #220a5a;
  color: #220a5a;
  transform-origin: 5px 15px;
  -webkit-animation: dotWindmill 2s infinite linear;
  animation: dotWindmill 2s infinite linear;
}

.actions {
  text-align: right;
  margin-right: -20px;
}

.es-btn {
  font-size: 12px;
  padding: 7px;
  min-width: 32px;
  margin-left: 6px;
  margin-bottom: 2px;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
  .v-text-field .v-label {
    z-index: 100;
  }
  .v-data-table-header th {
    text-align: left;
    color: white !important;
    background-color: #220a5a !important;
    &:first-child {
      border-top-left-radius: 4px;
      padding-left: 34px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
    &.active,
    & .v-data-table-header__icon {
      color: #ffe300 !important;
    }
    &.sortable {
      white-space: nowrap;
    }
  }
  .v-data-table tbody tr {
    &.expired {
      opacity: 0.3;
      background: #fee;
      cursor: not-allowed;
    }
    & td {
      padding-top: 18px !important;
      padding-bottom: 18px !important;

      &:first-child {
        padding-left: 34px;
        background-color: inherit
      }
      &:last-child {
        padding-right: 34px;
      }
    }
  }
}
</style>
